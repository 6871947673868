import type {
  GamingLobbyInterface,
  ProviderGamingListResApi,
  ResponseApiGamingBetTransactionInterface,
} from 'types/gaming';
import type { KeyedMutator } from 'swr';
import type { AxiosResponse, Method } from 'axios';
import { useCallback } from 'react';
import { objectify } from 'radash';
import type { Config } from './use-request';
import useRequest from './use-request';

export const useGamingLobby = (
  currency?: string,
  provider?: string,
  config?: Config<GamingLobbyInterface[]>,
): {
  data: GamingLobbyInterface[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<GamingLobbyInterface[]>>;
} => {
  return useRequest<GamingLobbyInterface[]>(
    provider
      ? {
          method: 'GET' as Method,
          url: `/gaming/${provider}/games`,
          params: {
            currency,
          },
        }
      : null,
    config,
  );
};

export const useAllGames = (
  currency?: string,
  config?: Config<ProviderGamingListResApi[]>,
) => {
  return useRequest<ProviderGamingListResApi[]>(
    {
      url: '/gaming',
      params: {
        currency,
      },
    },
    {
      revalidateOnFocus: true,
      ...config,
    },
  );
};

export const useGamingBetTransactions = (
  query: any = {},
  config?: Config<ResponseApiGamingBetTransactionInterface>,
) => {
  const provider = query.provider ? { provider: query.provider } : null;

  return useRequest<ResponseApiGamingBetTransactionInterface>(
    {
      url: '/gaming/bet-transactions',
      params: {
        limit: 100,
        startAt: query.startAt,
        endAt: query.endAt,
        ...provider,
      },
    },
    { ...config, revalidateOnFocus: true },
  );
};

export const useGamingInfoHelper = (currency: string) => {
  const { data } = useAllGames(currency);
  const getGamingProviderImage = useCallback(
    (provider: string) => {
      const gamingProvider = data?.find((i) => i.id === provider);
      if (!gamingProvider) {
        return;
      }
      return objectify(
        gamingProvider?.images,
        (f) => f.name as string,
        (f) => f.value as string,
      );
    },
    [data],
  );
  return {
    getGamingProviderImage,
  };
};
