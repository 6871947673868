import type { KeyedMutator } from 'swr';
import type { AxiosResponse, Method } from 'axios';
import type { LottoLobbyInterface, ResLottoInterface } from '../types/lotto';
import useRequest, { type Config } from '~hooks/use-request';

export const useLotto = (
  currency?: string,
): {
  data: ResLottoInterface[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<ResLottoInterface[]>>;
} => {
  return useRequest<ResLottoInterface[]>({
    method: 'GET',
    url: '/lottos',
    params: {
      currency,
      includeExpire: 1,
    },
  });
};

export const useLottoLobby = (
  currency: string,
  provider: string,
  config?: Config<LottoLobbyInterface[]>,
): {
  data: LottoLobbyInterface[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<LottoLobbyInterface[]>>;
} => {
  return useRequest<LottoLobbyInterface[]>(
    provider
      ? {
          method: 'GET' as Method,
          url: `/lottos/${provider}/games`,
          params: {
            currency,
          },
        }
      : null,
    config,
  );
};
