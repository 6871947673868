import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  ProviderSportResApiInterface,
  SportsbookLobbyInterface,
} from '../../types/sport';
import { useSportsbook } from '~hooks/use-sports-book';
import { onOpenSportURL } from '~libs/open-url';
import HttpErrorHandler from '~components/http-error-handler';
import getImageLists from '~libs/get-image-lists';
import PageUrls from '~constants/page-urls';
import { useRouter } from '~hooks/use-router';
import ProviderLoadingTemplate from '~components/widget/provider-loading-template';
import { ArrangementEnum } from '~constants/etc';
import ImageCustomWidget from '~components/widget/image-custom-widget';
import BentoMenuWidget from '~components/widget/bento-menu-widget';
import request from '~libs/request';

const SportProviderContainer: FC<{
  currency: string;
  walletObjectId: string | undefined;
  isAuthorized: boolean;
}> = ({ currency, walletObjectId, isAuthorized }) => {
  const router = useRouter();
  const { t } = useTranslation('errors');
  const isLightMode = document.documentElement.className.includes('light');
  const { data: providers, isLoading: isLoadingProvidersSport } =
    useSportsbook(currency);

  const providersSportMapped = useMemo<
    (ProviderSportResApiInterface & {
      logo: string;
    })[]
  >(() => {
    if (!providers) {
      return [];
    }
    return providers.map((gaming) => {
      const images = getImageLists(gaming?.images);

      return {
        ...gaming,
        logo: isLightMode ? images.logoDark : images.logo,
      };
    });
  }, [providers, isLightMode]);

  const onOpenSportProvider = async (
    game: ProviderSportResApiInterface,
  ): Promise<void> => {
    if (!isAuthorized) {
      router.push(PageUrls.LOGIN);
      return;
    }
    try {
      const currencyFiltered = game.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      window.open(
        await onOpenSportURL(game.objectId, currencyFiltered, walletObjectId),
        '_blank',
      );
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const onOpenSportsbookLobby = (id: string): void => {
    router.push(`${PageUrls.SPORT}/${id}`);
  };

  const handleOpenProvider = async (
    game: ProviderSportResApiInterface,
  ): Promise<void> => {
    let response: SportsbookLobbyInterface[] = [];
    try {
      response = await request<SportsbookLobbyInterface[]>({
        url: `/sportsbooks/${game.id}/games`,
        method: 'GET',
        params: {
          currency,
        },
      });
    } catch (e) {
      HttpErrorHandler(e);
    }

    if (response.length !== 0) {
      onOpenSportsbookLobby(game.id);
    } else {
      void onOpenSportProvider(game);
    }
  };

  if (isLoadingProvidersSport) {
    return <ProviderLoadingTemplate />;
  }

  return (
    <div className="flex flex-col gap-2">
      {providersSportMapped.map((provider, i) => (
        <BentoMenuWidget
          arrangement={ArrangementEnum.Horizontal}
          icon={
            <ImageCustomWidget
              alt={`game-${provider.id}`}
              className="sm:mt-0"
              image={provider.logo}
            />
          }
          key={i}
          onClick={() => {
            void handleOpenProvider(provider);
          }}
          text={provider.title || t('not-found-provider')}
        />
      ))}
    </div>
  );
};

export default SportProviderContainer;
