import type { CaslAbilityInterface } from 'types/wallet';

export enum PurchasesTypeEnum {
  RENEWABLE = 'renewable',
  ONGOING = 'ongoing',
  LIMITED = 'limited',
}

export enum WithdrawConditionTypeEnum {
  TURNOVER = 'TURNOVER',
  BILL = 'BILL',
  PROFIT = 'PROFIT',
}

export enum ProviderTypeEnum {
  ALL = '**',
  CASINO = 'casino',
  GAMING = 'gaming',
  MINIGAME = 'mini-games',
  SPORTBOOK = 'sportsbook',
  SPORT = 'sport',
  LOTTO = 'lotto',
  GAME = 'game',
  GAMEPACA = 'alpaca-studio',
  Lotto = 'lotto',
}

export interface AllowProviderType {
  providerName: string;
  logo?: string | React.ReactNode;
}

export type AllowedGameProvidersType = CaslAbilityInterface;
